$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-login-register {
    //background: url("../../assets/images/LoginRegisterPage2.png");
    background: url("../../assets/images/Login_BG_SieSales_720p_New.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    min-height: 111vh;
    overflow: auto;
    // @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    //   background: url("../../assets/images/Login_BG_SieSales_360p.png");
    // }

    .ssiat-login-logo-block {
        // padding: 24px 0 8px;
        padding: 30px 60px 10px;
        //text-align: center;

        @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
            text-align: center;
        }

        .official-logo {
            // width: 160px;
            height: 1.1rem;
            margin-top: 5px;
        }
    }

    .ssiat-lr-mobileContainer {
        padding: 0px 80px;
        justify-content: center;

        @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
            padding: 0px 20px;
        }

        .ssiat-lr--project-block {
            display: flex;
            flex-direction: column;
            //justify-content: center;

            .ssiat-lr--project {
                padding: 0 24px;
                margin-top: 40px;
                margin-bottom: 40px;
                width: 80%;
                border-width: 0px;
                border-left: 10px solid;
                border-image: linear-gradient( to bottom, var(--light-green), var(--light-green--2) ) 1 100%;

                @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
                    width: 100%;
                    padding: 0 48px 0 24px;
                    margin-left: 0px;
                }

                .ssiat-lr-project-name {
                    font-family: "SiemensSans-Black", sans-serif;
                    font-size: 27px;
                    line-height: 40px;
                    color: var(--dark-blue);
                    margin: 0;

                    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
                        font-size: 1.5rem;
                        line-height: 30px;
                    }

                    .ssiat-intra-logo {
                        height: 1rem;
                        margin: 0 10px;
                    }
                }

                .ssiat-lr--project-description {
                    font-size: 1.125rem;
                    display: block;
                    margin: 16px 0 0;
                }
            }
        }

        .ssiat-lr--master-block {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .ssiat-lr-block {
                //padding: 24px 40px;
                width: 80%;
                box-shadow: 0 0px 10px var(--light-grey);
                background-color: var(--white);
                opacity: 0.75;
                border-radius: 10px;
                //flex-grow: 1;

                @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
                    margin: 40px 0;
                    opacity: 0.95;
                }
                // span {
                //     text-align: center;
                //     font-size: 14px;
                //     font-weight: bold !important;
                // }

                .ssiat-login-siemens_user {
                    h3 {
                        margin: 0;
                    }

                    .myid-login-btn-block {
                        margin: 16px 0 0;
                    }
                }

                .ssiat-divider {
                    padding: 8px 0;

                    hr {
                        border-top: 3px dashed var(--light-grey);
                        border-bottom: none;
                    }
                }

                .ssiat-login-external_user {
                    h3 {
                        margin: 0;
                    }

                    .ssiat-login-form {
                        margin: 16px 0 0;

                        .ssiat-login-form-input {
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

.displayedTime {
    margin-top: 10px;
}

.count,
.split {
    background-color: #ebf7f8;
    padding: 8px;
}

.forget-pwd-cursor {
    cursor: pointer;
}

.forget-pwd-cursor:hover {
    color: var(--blue-green);
}

.heading-margin-5tb-0lr {
    margin: 5px 0px;
}

.pl-tab-2i {
    padding-left: 2em !important;
}

.pr-tab-2i {
    padding-right: 2em !important;
}

.mt-1 {
    margin-top: 1em;
}

.contact-us-text {
    font-style: italic;
    color: var(--petrol-green);
    // padding-bottom: 1em;
}
