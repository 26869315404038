.checkBoxCustClass {
    margin-top: 12px !important;
}

.checkBoxLabel {
    margin-top: 12px !important;
}

.checkBoxParentDiv {
    display: flex;
    align-items: center;
}

.custCloseIcon {
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;
    margin-right: 1%;
    font-size: 17px;
    font-weight: bolder;
}