:root {
    --light-sand: #F3F3F0;
    --light-sand--type-1: #D4D4CF;
    --dark-sand: #AAAA96;
    --dark-blue: #000028;
    --blue-green: #008AA6;
    --master-blue: #002949;
    --light-blue: #00BEDC;
    --petrol-green: #00CCCC;
    --light-green: #00FFB9;
    --light-green--2: #00E6DC;
    --transparent: transparent;
    --red: #FF5454;
    --light-red: #FF545480;
    --yellow: #FFD732;
    --gold-type-1: #FFE699;
    --gold-type-2:#F2E9D0;
    --amber: #FF9000;
    --green-type-4: #00646E;
    --green-type-3: #00AF8E;
    --green-type-2: #00D7A0;
    --green-type-1: #00FFB9;
    --white: #FFFFFF;
    --cool-white: #F2FCFC;
    --dark-purple: #500078;
    --light-purple: #AA32BE;
    --black: #000000;
    --light-grey: #CCCCCC;
    --shadow-grey: #CCCCCC33;
    --disable-text-color: #7D8099;
    --error-red-text-color: var(--red);
    --error-red-bg-dark-color: var(--red);
    --error-red-bg-color: var(--light-red);
    --error-red-bg-color-hover: #FF545426;
    --bg-gradient: linear-gradient(to right, var(--light-green), var(--light-green--2));
    /*Hero Button*/
    --btn-hero-bg-gradient: linear-gradient(to right, var(--light-green), var(--light-green--2));
    --btn-hero-text-color: var(--dark-blue);
    --btn-hero-bg-hover: #C5FFEF;
    --btn-hero-bg-active: #C5FFEF;
    /*Primary Button*/
    --btn-primary-text-color: var(--white);
    --btn-primary-bg-color: #008AA6;
    --btn-primary-bg-hover: #00646E;
    --btn-primary-bg-active: #00646E;
    /*Secondary Button*/
    --btn-secondary-bg-color: var(--white);
    --btn-secondary-text-color: var(--blue-green);
    --btn-secondary-bg-color-hover: #C2FFEE;
    --btn-secondary-text-color-hover: #005159;
    --btn-secondary-bg-color-active: #C2FFEE;
    --btn-secondary-text-color-active: #005159;
    /*Tertiary Button*/
    --btn-tertiary-bg-color: var(--white);
    --btn-tertiary-text-color: #008AA6;
    --btn-tertiary-bg-color-hover: #C2FFEE;
    --btn-tertiary-text-color-hover: #005159;
    --btn-tertiary-bg-color-active: #C2FFEE;
    --btn-tertiary-text-color-active: #005159;
    /*Delete Button*/
    --btn-delete-bg-color: var(--light-red);
    --btn-delete-text-color: var(--dark-blue);
    --btn-delete-bg-color-hover: var(--red);
    --btn-delete-text-color-hover: var(--white);
    /*Quick Links Button*/
    --btn-quick-links-bg-color: var(--master-blue);
    --btn-quick-links-text-color: var(--white);
    --btn-quick-links-bg-color-hover: #002949E6;
    --btn-quick-links-text-color-hover: var(--white);
    /*Input and Label*/
    --label-text-color: var(--dark-blue);
    --input-bg-color: #EBF7F8;
    --input-bg-color-hover: #D1FFF2;
    --input-bg-color-focus: #EBF7F8;
    --input-bg-color-active: #D1FFF2;
    --input-text-color: var(--dark-blue);
    --input-text-color-hover: var(--dark-blue);
    --input-bg-color-disabled: #EBF7F8;
    --input-text-color-disabled: #7D8099;
    /*Related Tags Chips*/
    --chips-bg-color: var(--blue-green);
    --chips-text-color: var(--blue-green);
    --chips-bg-color-hover: #00CCCC1F;
    --chips-text-color-hover: #00708C;
    --chips-bg-color-active: #00CCCC4D;
    --chips-text-color-active: #005673;
    /*Toggle Switch*/
    --toggle-switch-color: #0098A6;
    --toggle-switch-checked-color: #00646E;
    --toggle-switch-bg-color: var(--light-green);
    /*Accordian*/
    --accordian-active-bg-color: #B6F0ED;
    --accordian-active-heading-text-color: var(--master-blue);
    --accordion-box-shadow-color: #B6F0EDBF;
    --accordian-heading-bg-color-hover: #B6F0ED80;
    /*Accordion checkbox items*/
    --accordian-checkbox-bg-color: var(--white);
    --accordian-checkbox-bg-color-hover: #B6F0ED80;
    --checkbox-shadow: #00cccc60;
    /*Select dropdown*/
    --select-bg-color: #EBF7F8;
    --select-text-color: #4C4C68;
    --select-bg-color-hover: #D1FFF2;
    --select-text-color-hover: var(--dark-blue);
    --select-active-bg-color: #D1FFF2;
    --select-active-text-color: #000028;
    --select-disabled-bg-color: #EBF7F8;
    --select-disabled-text-color: #7D8099;
    --select-list-bg-color: var(--transparent);
    --select-list-text-color: var(--white);
    --select-list-item-bg-color: #62EEC7;
    --select-list-item-bg-color-hover: #62EEC780;
    /*Feedback*/
    --rating-yellow: #FFD732;
    --rating-orange: #FF9000;
    --feedback-btn-bg-color: #00646E;
    --feedback-btn-text-color: var(--white);
    --feedback-btn-bg-color-hover: #00AF8E;
    --feedback-btn-text-color-hover: var(--white);
    /*App Permission*/
    --app-permission-list-item-color: #00BEDC33;
    /*UnAuthorized Access*/
    --unauthorized-icon-bg-color: #FF9000;
    /*Side Drawer*/
    --side-drawer-bg-color: #002949F2;
    /* Landing page cards */
    --landing-page-card-bg-color: #D1FFF2;
    /* Circular progress */
    --circular-progress-color: #37d7b7;
}

/*Colors*/
.clr-dark-blue {
    color: var(--dark-blue);
}

.clr-master-blue {
    color: var(--master-blue);
}

.clr-siemens-green {
    color: var(--siemens-green);
}

.clr-light-green {
    color: var(--light-green);
}

.clr-light-green--2 {
    color: var(--light-green--2);
}

.clr-red {
    color: var(--red);
}

.clr-light-red {
    color: var(--light-red);
}


/*Background*/
.icon-background {
    background: var(--bg-gradient);
}

.bg-dark-blue {
    background: var(--dark-blue);
}

.bg-master-blue {
    background: var(--master-blue);
}

.bg-siemens-green {
    background: var(--siemens-green);
}

.bg-light-green {
    background: var(--light-green);
}

.bg-light-green--2 {
    background: var(--light-green--2);
}

.bg-red {
    background: var(--red);
}

.bg-light-red {
    background: var(--light-red);
}

/*Borders*/