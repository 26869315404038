.marginTop {
    //border-top: 4px dotted black;
    margin: 10px 0px;
    padding: 5px 0px 5px 0px;
}

.file-upload-form {
    width: 100%;

    .file-upload {
        margin-right: 24px;

        label {
            transform: translate(12px, 10px) scale(0.75);
        }
    }
}

.btnCenter {
    align-items: center;
    justify-content: center;
}

.dialogTitleTxt {
    text-align: center !important;
    justify-content: center;
}
/*.menu-item-container {
    transform: translateY(-15px) !important;*/
    /* From https://css.glass */
    /*background: var(--btn-hero-bg-gradient) !important;
    //border-radius: 16px  !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 230, 220, 0.3) !important;
}*/

  .menu-item-container {
        position: relative;
        display: flex;
        align-items: center;
        background: var(--btn-hero-bg-gradient);
        box-shadow: 0 0 10px var(--light-sand);
        border-radius: 8px;

        .menu-item-image-block {
            background: var(--white);
            margin: 10px;
            position: relative;
            left: -30px;
            border-radius: 10px;
            border: 1px solid var(--light-green);

            .menu-item-image {
                height: 80px;
                width: 80px;
                left: 40px;
            }
        }

        .menu-item-text-block {
            display: flex;
            padding: 20px;

            span {
                position: relative;
                font-size: 16px;
            }
        }
    }

.align-center {
    align-self: center;
}