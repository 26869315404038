@import "../src/assets/fonts.css";
@import "../src/assets/colors.css";

html,
body {
    padding: 0;
    margin: 0;
    background: var(--white);
    box-sizing: border-box;
    --scrollbarBG: var(--white) !important;
    --thumbBG: var(--green-type-3) !important;
}

    /*Scrollbar*/
    body::-webkit-scrollbar {
        width: 10px;
    }

body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

    body::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
    }

    body::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG);
        border-radius: 6px;
        border: 3px solid var(--scrollbarBG);
    }

div::-webkit-scrollbar {
    width: 8px;
}

div {
    scrollbar-width: thin;
    scrollbar-color: var(--green-type-3) var(--white) !important;
}

    div::-webkit-scrollbar-track {
        background: var(--white) !important;
    }

    div::-webkit-scrollbar-thumb {
        background-color: var(--green-type-3) !important;
        border-radius: 6px;
        border: 3px solid var(--white) !important;
    }

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "SiemensSans-Black", sans-serif;
    color: var(--dark-blue);
}

a {
    text-decoration: none;
    color: var(--dark-blue);
    display: block;
}

ul {
    background: var(--select-active-bg-color);
}

li {
    color: var(--dark-blue) !important;
}

/*Display*/
.d-flex {
    display: flex;
}

/*Flex direction*/
.fd-col {
    flex-direction: column;
}

.fd-col-rev {
    flex-direction: column-reverse;
}

.fd-row-rev {
    flex-direction: row-reverse;
}

/*Flex position*/
.jc-flex-start {
    justify-content: flex-start;
}

.jc-flex-end {
    justify-content: flex-end;
}

/*Flex Wrap*/
.flex-wrap {
    flex-wrap: wrap;
}

/*Justify Content*/
.jc-center {
    justify-content: center;
}

.jc-space-bt {
    justify-content: space-between;
}

.jc-space-around {
    justify-content: space-around;
}

.jc-space-event {
    justify-content: space-between;
}

.jc-space-evenly {
    justify-content: space-evenly;
}

.fd-column {
    flex-direction: column;
}
/*display*/
.d-block {
    display: block;
}

.align-items-center {
    align-items: center;
}

/* Z-index */
.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-5 {
    z-index: 5;
}

.z-6 {
    z-index: 6;
}

.z-7 {
    z-index: 7;
}

.z-8 {
    z-index: 8;
}

.z-9 {
    z-index: 9;
}

.p-0 {
    padding: o !important;
}

/* Paragraph and Span */
p {
    font-family: "SiemensSans-Bold", sans-serif;
}

span {
    font-family: "SiemensSans-Bold", sans-serif;
}

/* Text align */
.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

/* Text Transform */
.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.official-logo {
    height: 24px;
    width: 160px;
    margin: 0 16px 0 0;
}

.ssiat-master-block {
    background: var(--white);
}

.main-component {
    padding: 65px 32px 0px;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
    .main-component {
        padding: 80px 20px 20px;
    }
}

/* Input field with error */
/* .MuiFilledInput-root.Mui-error > input,
.MuiFilledInput-root.Mui-error > input:hover {
  background-color: transparent !important;
  color: var(--dark-blue);
} */

.MuiFilledInput-underline.Mui-error::before,
.MuiFilledInput-underline.Mui-error::after {
    border-bottom: 2px solid var(--red) !important;
}
/* Table pagination icon */
.MuiTableSortLabel-root {
    text-align: center;
}

.MuiTablePagination-selectIcon {
    margin: -8px;
}

.MuiInputBase-input.Mui-disabled {
    cursor: no-drop;
}

.MuiAlert-action svg {
    font-size: 2.25rem !important;
    cursor: pointer;
}
/* .MuiTableCell-alignLeft {
  text-align: revert !important;
} */
.MuiTableCell-alignRight {
    text-align: center !important;
    flex-direction: row-reverse;
}

.MuiToolbar-regular {
    min-height: 60px !important;
}

.al-center {
    align-items: center;
}

.table-padding {
    padding: 40px;
}
/*.view-block {
    width: 100%;
    margin-top: 15px;
    padding-top: 7px;
    border-top: 2px dashed #00bcdc;
   

}*/

.view-block {
    width: 100%;
    margin-top: 15px;
    padding-top: 7px;
    border-top: 2px dashed #00bcdc;
}

.view-blockterms {
    width: 100%;
}

.reject-btn {
    background-color: #FF545480 !important;
    color: black !important;
}

.add-item {
    display: flex;
}

.req-grid {
    margin-bottom: 20px !important;
}

.upload-section {
    margin: 10px 0px;
}

@media print {
    /*
     #printLetters, #printLetters * {
        visibility: visible;
      } 
     #printLetters {
        position: absolute;
        left: 0;
        top: 0;
    } 
    .pagebreak {
        page-break-before: always;
    }

    .pagebreakAfter {
        page-break-after: always;
    }*/

    .no-print {
        display: none !important;
        visibility: hidden !important;
    }

    .disableOnPrint-scrollToTop {
        display: none !important;
        visibility: hidden !important;
    }

    .bg-intact {
        -webkit-print-color-adjust: exact !important;
    }

    body {
        font-size: 10pt;
        transform: translateZ(0) !important;
        -webkit-font-smoothing: subpixel-antialiased !important;
        backface-visibility: hidden !important;
        color: black !important;
    }
}

@media screen {
    body {
        font-size: 16px
    }
}

@media screen, print {
    body {
        line-height: 1.2
    }
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

/*@media print {
    footer {
        page-break-after: always;
    }
}

@media print {
    grid {
        page-break-after: always;
    }
}

@media print {
    .newpage {
        page-break-before: always;
    }
}*/

.newpage {
    page-break-after: always;
}

@media print {
    .media-print {
        padding-left: 450px !important;
    }
}

@media screen {
    .media-screen {
        padding-left: 900px !important;
    }
}

/*.hidden-print {
    display: none !important;
}*/


@media print {
    .hidden-print {
        display: none !important;
    }
}
.print-menu {
    position: absolute;
    right: 0;
    margin-right: 28px;
}

@media print{
    .hidden-print {
        display: none !important;
    }

}

/*@media print {
    @page {
        margin: 0;
    }
}*/