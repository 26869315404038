.gridMargin {
  padding-top: 6px;
}
.textCenter{
    text-align: center;
}
.separator {
    border-bottom: 3px dashed #008aa6;
}
.margonTop{
    margin: 10px 0px 10px 0px;
}